@import '../variables.scss';

.consentContainer{
    margin-top: 1rem;
    position: relative;
    .consentSection {
        padding: 1.5rem;
        min-height: 82vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
            display: flex;
            justify-content: space-evenly;
            @media only screen and (max-width: 800px) {
                display: block;
            }
            .column {
                width: 50%;
                @media only screen and (max-width: 800px) {
                    width: 100%;
                }
            }
        }
        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .header {
            margin: 0 0 1rem 0;
            color: $secondary;
        }
        .dataItem {
            margin: 0 0 0.5rem 0;
            color: $black;
            font-weight: bold;
        }
        .subDataItem {
            margin: 0 0 0.5rem 0;
            color: $darkGray;
        }
        .label {
            margin: 0 0 1rem 0;
            color: $darkGray;
        }
        .treatmentPlans {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            color: $black;
            margin-top: 1rem;
            svg {
                &:hover {
                    color: $primary;
                }
            }
        }
        .dropzone {
            height: 20rem;
            width: 25rem;
            border: 1px solid $gray;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .videos {
            display: flex;
            gap: 1rem;
            flex-direction: row-reverse;
            @media only screen and (max-width: 800px) {
                display: flex;
                flex-direction: column-reverse;
            }
            .playListContainer {
                display: flex;
                flex-direction: column;
                width: 35%;
                max-height: 400px;
                overflow-y: none;
                position: relative;
                @media only screen and (max-width: 800px) {
                    width: 100%;
                }
                .playList {
                    overflow-y: scroll;  
                }
                .tabList {
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 1rem;
                  }
                
                // 21081991
                .video {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1rem;
                    gap: 2rem;
                    position: relative;
                    @media only screen and (max-width: 800px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .thumbnail {
                        width: 100px;
                        height: auto;
                        border-radius: 20px;
                        padding: 10px;
                        cursor: pointer;
                    }
                    .description {
                        height: auto;
                        width: 100%;
                        display: flexbox;
                        align-items: center;
                        justify-content: space-between;
                       
                        span {
                            white-space: pre-line;
                            padding: 1rem;
                        }
                        svg {
                            &:hover {
                                color: $primary;
                            }
                        }
                        &.active {
                            border: 2px solid $primary;
                        }
                    }
                    .status {
                        color: $darkGray;
                    }
                }
            }
            .player {
                width: 65%;
                min-height: 300px;
                @media only screen and (max-width: 800px) {
                    width: 100%;
                    min-height: auto;
                }
                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }
            }
        }
        .noVideos {
            align-self: center;
        }
        .footer {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
        }
        .consentForm {
            img {
                max-width: 90vw;
                height: auto;
            }
        }
        
    }
    .acknowledgeSection {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-evenly;
    }
    
    .signPad {
        position: relative;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        .signCanvas {
            position: absolute;
            background: white;
            bottom: 0;
            border-radius: 16px;
            border: 1px solid $primary;
            width: calc(100% - 2rem);
            height: 500px;
            margin: 0 1rem;
        }
        .signCanvasBackground {
            position: absolute;
            background: white;
            bottom: 0;
            border-radius: 16px;
            border: 1px solid #243474; /* Replace $primary with the actual color */
            width: calc(100% - 2rem);
            height: 500px; /* Adjust as needed */
            margin: 0 1rem;
            background-image: url("./signature.jpg");
            background-size: 280px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 1; /* You can use a state variable to control opacity */
            transition: opacity 0.3s ease; /* Smooth transition for hiding */
        }
        .signFooter {
            padding: 1rem 2rem;
            display: flex;
            justify-content: space-between;
        }
        .close {
            position: absolute;
            top: 0;
            right: 15px;
        }
    }
    button {
        &:disabled {
            background: darkgray;
        }
    }

    .dialog-paper {
        border-radius: 16px; /* Rounded edges */
        border: 2px solid orange; /* Orange color outline */
      }
      
      .dialog-title {
        color: blue; /* Blue color for the title text */
        display: flex;
        align-items: center;
      }
      
      .dialog-title img {
        width: 24px; /* Adjust size as needed */
        height: 24px;
        margin-right: 8px; /* Space between logo and title */
      }

      .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #ffecd1; /* Light orange background */
        padding: 20px;
        border-radius: 8px;
        border: 2px solid rgb(0, 0, 199); 
      }
      
      .bannerLogo {
        width: 150px; /* Reduce the logo size */
        height: auto;
        margin-bottom: 10px;
      }
      
      .bannerText {
        font-size: 1.2rem;
        color: #243474;
        font-weight: bold;
        margin: 0;
        line-height: 1.5;
      }
      
     
} 



.unsigned-btn {
    position: relative;
  }
  
  .unsigned-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

.notification {
    font-family: Arial, sans-serif;
    font-size: 17px;
    transition: opacity 0.3s ease;
  }
  
  .notification.info {
    background-color: #007bff;
  }
  
  .notification.success {
    background-color: #28a745;
  }
  
  .notification.error {
    background-color: #dc3545;
  }
  










.buttonRow {
    display: flex;
    gap: 10px; /* Space between buttons */
  }
.banner {
    background-color: #ffcccb;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: #d32f2f;
    border-bottom: 1px solid #d32f2f;
  }
button {
    background-color: #ff6a00; /* Orange color */
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 12px; /* Round edges */
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  /* Optional button hover effect */
  .optional-signature-btn:hover {
    background-color: #ff6a00; /* Darker orange when hovered */
  }
  
  /* Mandatory button hover effect */
  .mandatory-signature-btn:hover {
    background-color: #ff6a00; /* Darker orange when hovered */
  }
  
  /* Style for the asterisk (for mandatory signature button) */
  .mandatory-signature-btn .asterisk {
    color: rgb(255, 0, 0);
    font-weight: bold;
    margin-left: 5px;
  }

  